
export default {
  name: 'label',
  components: {},
  props: {
    label: "Label"
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


