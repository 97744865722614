import ExpItem from "@/components/cv/ExpItem/ExpItem.vue"

export default {
  name: 'cv',
  components: {ExpItem},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


