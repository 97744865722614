import ScaleAnimationView from "@/components/shared/views/scaleAnimationView/ScaleAnimationView.vue"
import Animatable from "@/components/shared/views/animatable/Animatable.vue"
import AboutSection from "@/components/home/aboutSection/AboutSection.vue"
import MusicSection from "@/components/home/musicSection/MusicSection.vue"

export default {
  name: 'home',
  components: {
    Animatable,
    ScaleAnimationView,
    AboutSection,
    MusicSection
  },
  props: [],
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  }
}
