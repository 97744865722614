import Label from "@/components/shared/controls/label/Label.vue"

export default {
  name: 'Input',
  components: {Label},
  props: [
    "label",    
    "placeholder",
    "value",
    "type",
    "updateCallback"
  ],
  data () {
    return {
    }
  },
  computed: {
    formattedType(){
      const type = this.type
      if(!type)
        return "number"
      if(type === "Integer")
        return "number"
      return "text"
    }
  },
  mounted () {

  },
  methods: {
    onInputEdit: function(e){
      const target = e.target
      this.formatter(target)
      this.updateCallback(target)
    },
    formatter : function(target){
      const value = target.value
      if(this.type == "Integer")
        target.value = Math.floor(value)
    }
    }
}


