<div class="tax-rate-cont">
    <Headline text="Indtast din trækprocent">
        <div style="width: 192px;">
            <h2>Info</h2>
            <p>
                Angiv din trækprocent i hele tal. <br><br>

                Gyldigt input: 0 - 100
            </p>
        </div>
    </Headline>
    <div class="tax-rate-wrapper">
        <Input type="Integer" placeholder="Trækprocent" label="%" :value="taxRate" :updateCallback="updateTaxRate" />
        <AMControls :decreaseCallback="decreaseRate" :increaseCallback="increaseRate" />
    </div>
</div>