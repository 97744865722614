import Input from "@/components/shared/controls/input/Input.vue"
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'taxExcemptions',
  components: { Input },
  props: {
    salaryInfo: {}
  },
  data() {
    return {
      title: "Fri telefoni",
      value: 350
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    updateTitle(target) {
      this.title = target.value
    },
    updateValue(target) {
      this.value = target.value
    },
    addBenefit() {
      if (this.isNotValid())
        return
      this.createAndAppend(this.title, this.value)
      this.clearValues()
    },
    isNotValid() {
      return this.title === "" || this.value === 0
    },
    createAndAppend(title, value) {
      const benefit = {
        "title": title,
        "value": value,
        "id": uuidv4()
      }
      this.salaryInfo.benefits.push(benefit)
    },
    clearValues() {
      this.title = "Fri telefoni"
      this.value = 350
    },
    removeBenefit: function (id) {
      this.salaryInfo.benefits = this.salaryInfo.benefits
        .filter(ex => ex.id !== id)
    },
    duplicateBenefit: function (id) {
      const candidate = this.salaryInfo.benefits
        .find(ex => ex.id === id)
      if (!candidate)
        return
      this.createAndAppend(candidate.title, candidate.value)
      this.clearValues()
    }
  }
}


