<div id="cv-cont" class="horizontal-center">
  <h1>Curriculum Vitae</h1>
  <br>
  <div id="meta-cont">
    <p id="meta-text">
      Navn: &nbsp; Martin Hansen<br>
      Adresse: &nbsp; Hyben Alle 56 1.MF, 2770 Kastrup<br>
      Telefon: &nbsp; 31165870<br>
      E-mail: &nbsp; MH2012@outlook.dk<br>
      Fødselsdato: &nbsp; 3. juni 1985<br>
      Civilstatus: &nbsp; Single<br>
    </p>
    <img id="meta-img" src="./migselv.png" width="192">
  </div>
  <br>
  <br>
  <h2>Resumé</h2>
  <p>
    Som en nyuddannet softwareudvikler med en passion for at lære og vokse i rollen, søger jeg at begynde min karriere
    inden for feltet med en solid grundlæggende forståelse for softwareudviklingens principper.<br><br>

    Jeg har modtaget undervisning i grundlæggende softwareudviklingsprincipper og har en stærk interesse i at anvende
    disse koncepter i praksis. Selvom jeg måske ikke har omfattende erfaring, er jeg dedikeret til at lære og udvikle
    mine færdigheder i softwareudviklingens verden.<br><br>

    Jeg er en holdspiller med gode kommunikationsevner og evnen til at samarbejde effektivt med kolleger. Jeg er ivrig
    efter at bidrage til projekter og lære af erfarne udviklere i teamet for at accelerere min faglige
    udvikling.<br><br>

    Med min dedikation til at lære, min passion for teknologi og min evne til at tilpasse mig nye udfordringer, er jeg
    overbevist om, at jeg vil kunne gøre en positiv forskel som en del af jeres softwareudviklingsteam.<br>
  </p>
  <h2>Personlige kompetencer</h2>
  <ul>
    <li>Resultatorienteret</li>
    <li>Arbejdsom</li>
    <li>Initiativrig</li>
    <li>Løsningsorienteret</li>
  </ul>
  <h2>Faglige kompetencer</h2>
  <ul>
    <li>Fullstack web løsninger i .NET og Java miljøer</li>
    <li>Mobil og desktop løsninger i Qt miljø</li>
    <li>Kan operere i Linux miljøer</li>
    <li>Kan arbejde med Git i terminal</li>
  </ul>
  <h2>Erhvervserfaring</h2>
  <br>
  <ExpItem year="2024" title="Det Grønne Team">
    Arbejdede med brolægning, gravearbejde i forbindelse med
    vedligehold af blomsterbede.
    Var med til bygning og opsætning af redskabsskure
    for en nyttehaveforening.
  </ExpItem>
  <ExpItem year="2022" title="Københavns Erhvervsakadami">
    Arbejde med fullstack web løsninger med fokus på MySQL databaser.
    Modtaget undervisning f.eks. i Scrum, Git branching strategier
    og test metodikker. <br>
    Dette studie er pt. Afbrudt, men planlægges genopstartet igen.
  </ExpItem>
  <ExpItem year="2021" title="Dagrofa Food Store">
    Engros forhandler hvor butiksindehavere kunne købe varer til deres butikker.
    Arbejdet bestod af opsætning og trimning af varer,
    modtagelse af kundepant samt en del kundekontakt.
  </ExpItem>
  <ExpItem year="2020 - 2021" title="IT Universitet København">
    Et kort ophold hvor jeg tilegnede mig viden omkring projekt videnskab og rapport skrivning.
  </ExpItem>
  <ExpItem year="2018 - 2020" title="Danmarks Tekniske Universitet">
    Modtaget undervisning i softwareteknologier på et teoretisk niveau.
    Arbejdede med netværksprotokoller, low level programmering,
    matematik, algoritmer og datastrukterer, osv..
  </ExpItem>
  <ExpItem year="2015 - 2018" title="AVU, HF">
    10. klasse og HF på KBHSyd Tårnby afdeling.
  </ExpItem>
  <ExpItem year="2014" title="AVU, HF">
    En 6 måneders praktikforløb hvor jeg arbejdede med vedligeholdelse
    af kommunale institutioner og områder.
    Arbejdet bestod primært af gadefej og flis arbejde på f.eks.
    børneinstitutioner og skov områder på Vestamager.
  </ExpItem>
  <ExpItem year="2003-2013" title="AVU, HF">
    Har været både airside og landside som specialarbejder.
    Arbejdet på airside bestod af alt muligt forefaldendt arbejde
    såsom flytteopgaver, gadefej af området omkring ”fingrene”,
    småt og stort dagrenovation, bagagehåndtering, osv..
    På landside bestod arbejdet primært af vedligeholdelse af
    området omkring Terminal 2 & 3 samt indenrigsterminalen.
    Arbejdet inkluderede blandt andet også kundekontakt.
  </ExpItem>
  <br>
  <h2>Sprog, teknologier og frameworks</h2>
  <br>
  <div id="lang-grid">
    <p>Objektorienteret sprog</p>
    <p>C, C++, Java, C#</p>
    <p>Script sprog</p>
    <p>JavaScript</p>
    <p>Deklarative sprog</p>
    <p>HTML, QML, SQL</p>
    <p>Frameworks</p>
    <p>
      .NET Blazor WASM, .NET Core API,
      Spring Boot, Hibernate, Qt, VueJS, AngularJS, React, React-Native
    </p>
    <p>DevOps</p>
    <p>
      Jira, GitHub Projects, Maven, CMake, QMake,
      Junit, NUnit, QTest
    </p>
  </div>

  <h2>Hobby projekter og portefølje</h2>
  <p>
    I min fritid elsker jeg at sidde og fifle med projekter af varierende størrelser. Lige for tiden sidder jeg og
    arbejder på et ordre håndterings system som jeg fik inspiration til gennem et tidligere sommerjob i min lokale
    Letkøb. <br><br>

    Projektet omhandler plukning og afsendelse af dagligdagsvarer til mennesker der af forskellige årsager er forhindret
    i at kunne afhente varer i butikken. Letkøb er en lille forretningskæde som størrelsesmæssigt må betegnes som et
    lille ”minimarked” og drives af lokale købmænd. Filialen i Kastrup tilbyder udbringning af varer til nærområdet
    (faktisk til hele Amager med bil) hvor kunderne ringer til butikken og afgiver ordren telefonisk. Ordren bliver så
    herefter nedskrevet på papir og lagt på et spyd hvor de håndteres efter FILO (First In Last Out) princippet. Under
    mit ansættelsesforhold oplevede jeg at kunder blev betjent sent (grundet FILO) og medarbejderen (mig selv) havde
    svært ved at tyde teksten grundet dårlig skrift (i hvert fald en medarbejder var ordblind). Disse problematikker gav
    mig idéen til mit nuværende projekt.
  </p>
  <p>Løsningen består af følgende projekter:</p>
  <ul id="proj-list">
    <li>Administrativt frontend</li>
    En web applikation skrevet i C# og.NET Blazor. Lavet til administratorer til håndtering af ressourcer
    <li>API til administratorer</li>
    Et .NET 7 Web Api projekt skrevet i C# som er et REST API til persistens af ressourcer. Benytter lige pt. MySQL
    database og Entityframework til håndtering af Entiteter og Database manipulation.
    <li>API til medarbejder </li>
    Et .NET Web Api projekt skrevet i C# som er et REST API til håndtering af ordrer. Projektet benyttes af mobil
    applikationen til at ændre ordre status og de enkelte vares status (Er de plukket eller ej).
    <li>API til brugerhåndtering (IAAM) </li>
    Et .NET 7 Web Api projekt skrevet i C# som er et REST API til håndtering af brugere. Står for oprettelse af JWT
    tokens og persistens af brugere. Benytter Identity frameworket.<br><br>

    Her kunne en interessant og fremtidig løsning være Auth0 eller Microsoft Entra ID.
    <li>Mobil applikation til medarbejdere </li>
    Mobil klient skrevet i QML og C++ som skal benyttes af medarbejder til at ændre status på ordrer og varer.
  </ul>
</div>