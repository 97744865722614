import Report from "@/services/salaries/report/report.js"

const hideClassName = "hide-scrollbar"
const showClassName = "show-scrollbar"

let cont = {}

function showScrollbar(){
  cont.classList.remove(hideClassName)
}

function hideScrollbar(){
  cont.classList.add(hideClassName)
}

export default {
  name: 'FinalReport',
  components: {
  },
  props: {
    salaryInfo: {}
  },
  data () {
    return {
      report : function(){
        return Report(this.salaryInfo)
      }
    }
  },
  computed: {
    formattedTaxRate: function(){
      return Math.round(this.salaryInfo.taxRate * 100)
    }
  },
  mounted () {
    cont = document.querySelector(".values-cont")
    hideScrollbar()
  },
  methods: {
    updateScrollbar: function(){
      showScrollbar()
      setTimeout(() => {
        hideScrollbar()
      }, 500);
    }
  }
}


