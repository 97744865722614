<div class="report-cont">
    <div class="values-cont" :onscroll="updateScrollbar">
        <h2> Dine indtastninger </h2>
        <p>Timeløn: <span class="normal-value">{{ salaryInfo.hourlyRate }} kr.</span></p>
        <p> Månedlige timer: <span class="normal-value"> {{ salaryInfo.monthlyHours }} t </span></p>
        <p>Trækprocent: <span class="normal-value"> {{ formattedTaxRate }}% </span></p>
        <h2>Personale goder</h2>
        <p>Værdi af goder: <span class="normal-value"> {{ report().salaryReport.totalBenefits }} kr. </span></p>
        <h2>Brutto og pension</h2>
        <p> Bruttoløn: <span class="normal-value">{{ this.report().salaryReport.brutto }} kr.</span></p>
        <p> Pension: <span class="normal-value">{{ this.report().salaryReport.pension }} kr.</span></p>
        <p> ATP: <span class="normal-value">{{ this.report().salaryReport.atp }} kr.</span></p>
        <h2>Fradrag</h2>
        <p>Personligt fradrag: <span class="normal-value"> {{ report().taxReport.personalDeduction }} kr. </span></p>
        <p>Beskæftigelses fradrag: <span class="normal-value"> {{ report().taxReport.employmentDeduction }} kr. </span>
        </p>
        <p>Job fradrag: <span class="normal-value"> {{ report().taxReport.jobDeduction }} kr. </span></p>
        <h2>Skatter</h2>
        <p>Skat: <span class="normal-value"> {{ report().paymentReport.taxes }} kr. </span></p>
        <p>&nbsp - <i>AM bidrag: </i><span class="normal-value"> {{ report().taxReport.amContribution }} kr. </span></p>
        <p>&nbsp - <i>Grundskat: </i><span class="normal-value"> {{ report().taxReport.baseTax }} kr. </span></p>
        <p>&nbsp - <i>Topskat: </i><span class="normal-value"> {{ report().taxReport.upperTax }} kr. </span></p>
    </div>
    <p class="result-par">
        Udbetaling:
        <span class="result-value">{{ report().paymentReport.netto}}kr.</span>
    </p>
</div>