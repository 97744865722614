const sectionClassName = "animatable-cont"
let sectionHeight = 0
let windowTopAnchor = 0
let ratio = 0
let sectionElements = []
const offset = 128

function isApproaching(el) {
  const topAnchor = el.topAnchor
  const bottomAnchor = el.bottomAnchor
  return topAnchor < windowTopAnchor &&
    bottomAnchor > windowTopAnchor
}

function scaleIn(el) {
  const topAnchor = el.topAnchor
  const diff = windowTopAnchor - topAnchor
  ratio = diff / (sectionHeight - offset)
  el.element.style.scale = ratio > 1 ? 1 : ratio
}

function updateElements() {
  sectionElements
    .forEach(el => {
      if (isApproaching(el))
        scaleIn(el)
      else
        el.element.style.scale = 1
    })
}

function createElement(el) {
  el.style.scale = 1
  const rect = el.getBoundingClientRect()
  return {
    "topAnchor": rect.top,
    "bottomAnchor": rect.bottom,
    "element": el
  }
}

function initSectionElements() {
  const elements = document.querySelectorAll(`.${sectionClassName}`)
  sectionElements = []
  elements.forEach(el => {
    const child = el.children[0]
    const sectionElement = createElement(child)
    sectionElements.push(sectionElement)
  })
}

function initVars() {
  innerWidth = window.innerWidth
  sectionHeight = window.innerHeight
  windowTopAnchor = sectionHeight
}

export default {
  performAnimation() {
    initVars()
    initSectionElements()
    updateElements()
  }
}