<div class="plate-cont">
  <Animatable>
    <div class="plate-lout fill-height">
      <div class="plate-image music-img"></div>
      <h2 class="plate-heading">Musik</h2>
      <p class="plate-text">
        Trance musik, dødsmetal, nyklassisk, blues, osv..
      </p>
    </div>
  </Animatable>
  <Animatable>
    <div class="fill-height">
      <p class="plate-text">
        Har altid haft en forkærlighed for trance musikken som jeg mere eller mindre
        voksede op med i de sene 90'ere. Goa-Trance var jeg specielt glad for. Dens energi og
        atmosfære var noget der tiltalte mig fra starten af.<br><br>
      </p>
      <div class="music-collage horizontal-center">
        <div class="cover-image ravermesiter-image"></div>
        <div class="cover-image ravemission-image"></div>
        <div class="cover-image ravenow-image"></div>
        <div class="cover-image goahead-image"></div>
      </div>
      <p class="plate-text">
        <i>Goa-Head</i>, <i>Destination Goa</i>, <i>Ravermeister</i>, <i>Rave Now</i>, <i>Rave Mission</i> 
        var alle eksempler på populære kompilationer jeg rendte ind i Streetdance, Fona, Fredgaard, osv., 
        og brugte mine lommepenge på og spillede til uendelighed på mit Magnavox stereo anlæg.
      </p>
    </div>
  </Animatable>
  <div style="height: 192px;"></div>
  <Animatable>
    <div class="fill-height">
      <h2 class="plate-heading">Blues & Swamp Rock</h2>
      <br>
      <br>
      <div class="blues-collage relative-cont">
        <div class="tony-image image-dims"></div>
        <div class="stevie-image image-dims"></div>
        <div class="beth-image image-dims"></div>
        <div class="joe-image image-dims"></div>
      </div>
    </div>
  </Animatable>
</div>