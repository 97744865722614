import Input from "@/components/shared/controls/input/Input.vue"
import Radios from "@/components/shared/controls/radios/Radios.vue"
import Select from "@/components/shared/controls/select/Select.vue"
import Headline from "@/components/shared/Headline/Headline.vue"

const salaryModes = ["hourly", "fixed"]

function validateValue(el) {
  if (el.value < 0)
    el.value = ""
}

export default {
  name: 'salary-selector',
  components: { Input, Radios, Select, Headline },
  props: {
    salaryInfo: {}
  },
  data() {
    return {
      salaryModeLabels: ["Timeløn", "fast"],
      hours: 0
    }
  },
  computed: {

  },
  mounted() {
    this.hours = this.salaryInfo.monthlyHours
  },
  methods: {
    updateSalaryMode: function (index) {
      this.salaryInfo.salaryMode = salaryModes[index]
    },
    updateWelfareSubsidy: function (target) {
      validateValue(target)
      this.salaryInfo.welfareSubsidy = target.value
    },
    updateHourlyRate: function (target) {
      validateValue(target)
      this.salaryInfo.hourlyRate = target.value
    },
    updateHours: function (target) {
      validateValue(target)
      this.hours = target.value
      const period = this.salaryInfo.hourPeriod
      this.updateSalaryInfo(period, target.value)
    },
    updateJobStatus(value) {
      this.salaryInfo.hasJob = value == "Løn"
    },
    updatePeriod: function (model) {
      this.salaryInfo.hourPeriod = model
      this.updateSalaryInfo(model, this.hours)
    },
    updateSalaryInfo: function (period, value) {
      if (period === "weekly")
        this.salaryInfo.monthlyHours = value * 4
      else
        this.salaryInfo.monthlyHours = value
    }
  }
}