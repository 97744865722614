import Input from "@/components/shared/controls/input/Input.vue"

let navContainer

export default {
  name: 'navigation',
  components: { Input },
  props: [],
  data() {
    return {
      menuOpen: false
    }
  },
  computed: {

  },
  mounted() {
    navContainer = document.getElementById("nav-cont")
  },
  methods: {
    openMenu: function (e) {
      this.menuOpen = !this.menuOpen
      navContainer.style.display = this.menuOpen ? "grid" : "none"
    },
    closeMenu: function (e) {
      const width = window.innerWidth
      if (width < 1280) {
        this.menuOpen = false
        navContainer.style.display = "none"
      }
    }
  }
}


