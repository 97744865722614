<div class="plate-cont">
  <Animatable>
    <div class="plate-lout fill-height">
      <div class="plate-image self-img"></div>
      <h2 class="plate-heading">Martin Hansen</h2>
      <p class="plate-text">
        &#10013; 3. juni 1985 &ThinSpace; &#9963; Kastrup &ThinSpace; &#9829; Single
      </p>
    </div>
  </Animatable>
  <Animatable>
    <div class="fill-height">
      <p class="head-syms">&#10013;</p>
      <p class="plate-text">
        Født på rigshospitalet.
        <br>
        <br>
        Tilbragte en del af min barndom i Kastrup 
        og teenage årene i hhv. Nødebo og Bagsværd.
        <br>
        <br>
        Fik en etværelses lejlighed i Kastrup i 2003
        som jeg har boet i lige siden.
      </p>
    </div>
  </Animatable>
  <Animatable>
    <div class="fill-height">
      <p class="head-syms">&#9963;</p>
      <p class="plate-text">
        Bor i Skottegården i en lille etværelses lejlighed på 39 kvm med en husleje på lige knap
        2900 kr. om måneden<br><br>
        <img class="responsive-image horizontal-center" src="./images/junkie_home.jpg">
      </p>
    </div>
  </Animatable>
</div>