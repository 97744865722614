<div class="emp-cont">
  <h2>Nuværende status</h2>
  <Radios :labels="this.employmentLabels" :selectedIndex="this.employmentIndex"
    :updateCallback="updateEmploymentMode" />
  <br>
  <p v-if="!this.salaryInfo.hasJob" class="emp-sel-msg">
    Vælg hvis du er på en eller anden form for overførselsindkomst
  </p>
  <p v-else class="emp-sel-msg">
    Vælg dette hvis du er i job!
  </p>
</div>