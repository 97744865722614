export default {
  name: 'info-box',
  components: {},
  props: [],
  data () {
    return {
      showTip: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    showInfoTip(e){
      this.showTip = true
    },
    hideInfoTip(e){
      this.showTip = false
    }
  }
}


