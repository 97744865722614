<div id="nav-cnt">
  <div id="navbar-handle" :onclick="openMenu"></div>
  <div id="nav-cont" class="horizontal-center" :onclick="closeMenu">
    <router-link class="link-el" to="/">
      Start
    </router-link>
    <router-link to="/salaries" class="link-el">
      Løn
    </router-link>
    <router-link to="/cv" class="link-el">
      CV
    </router-link>
    
  </div>
</div>