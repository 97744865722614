
export default {
  name: 'exp-item',
  components: {},
  props: [
    "year", "title"
  ],
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


