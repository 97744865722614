import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'radios',
  components: {},
  props: [
    "labels",
    "selectedIndex",
    "updateCallback",
  ],
  data() {
    return {
      currentSelectedIndex: 0,
      instanceId : uuidv4(),
      containerEl : null,
      handleEl: null,
      radios: [],
      indexId : 0 
    }
  },
  computed: {
    containerId(){
      return `radios@${this.instanceId}`
    },
    handleId(){
      return `handle@${this.instanceId}`
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init: function(){
      this.initVars()
      this.initElements()
      this.adjustHandle()
      this.updateFontColors()
    },
    initVars: function(){
      this.containerEl = document.getElementById(this.containerId)
      this.handleEl = document.getElementById(this.handleId)
      this.currentSelectedIndex = this.selectedIndex
    },
    initElements: function(){
      this.radios = this.containerEl.querySelectorAll(".radio-cont")
    },
    adjustHandle: function(){
      const index = this.currentSelectedIndex
      this.handleEl.style.width = `${this.handleWidth(index)}px`
      this.handleEl.style.left = `${this.handlePosition(index)}px`
    },
    updateFontColors: function(){
      const index = this.currentSelectedIndex
      for (let i = 0; i < this.radios.length; i++) {
        const el = this.radios[i];
        el.style.color = i !== index ? "black" : "wheat"
      }
    },
    handlePosition: function (index){
      if(index < 0 || index >= this.radios.length)
        return -1
      else if(index === 0)
        return 0
      let position = 0
      for (let i = 0; i < index; i++) {
        const el = this.radios[i];
        position += el.getBoundingClientRect().width + 6
      }
      return position
    },
    handleWidth: function(index){
      const element = this.radios[index]
      return element.getBoundingClientRect().width
    },
    moveHandle: function(label){
      this.currentSelectedIndex = this.labels.indexOf(label)
      this.adjustHandle()
      this.updateFontColors()
      this.updateCallback(this.currentSelectedIndex)
    }
  }
}


