import SalaryPage from "./pages/salaryCalculation/SalaryCalculation.vue"
import HomePage from "@/pages/home/Home.vue"
import CV from "@/pages/cv/CV.vue"
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    { path: "/", component: HomePage },
    { path: '/salaries', component: SalaryPage },
    {path: '/cv',component: CV},
    {path: '/*', component: HomePage }
]

const config = {
    history: createWebHashHistory(),
    routes
}

const router = createRouter(config)
router.onError(() => {
    console.log("Nav error happened!")
})

export default router