// Constants
const upperTaxThreshold = 588900 / 12
const upperTaxRate = 0.15
const AM = 0.08
const employmentThreshold = 45100 / 12
const employmentPercentage = 0.1065
const jobThreshold = 216100 / 12
const jobPercentage = 0.045
const jobDeductibleLimit = 2800 / 12
const personalDeduction = 49700 / 12
// Vars
let aIncome = 0
let amContribution = 0
let employmentDeduction = 0
let jobDeduction = 0
let monthlyDeductions = 0
let taxable = 0
let baseTax = 0
let upperTax = 0

export default function (salaryInfo, salaryReport) {
    calculateAM(salaryInfo.hasJob, salaryReport.amBasis)
    calculateDeductions()
    calculateTaxes(salaryInfo.taxRate)
    return taxReport()
}

function calculateAM(hasJob, brutto) {
    amContribution = hasJob ? Math.floor(brutto * AM) : 0
    aIncome = hasJob ? Math.ceil(brutto - amContribution) : brutto
}

function calculateDeductions() {
    employmentDeduction = calcEmploymentDeduction()
    jobDeduction = calcJobDeduction()
    const totalDeductions = employmentDeduction + personalDeduction + jobDeduction
    monthlyDeductions = Math.round(totalDeductions)
}

function calcEmploymentDeduction() {
    const deduction = aIncome * employmentPercentage
    return deduction > employmentThreshold ? employmentThreshold : deduction
}

function calcJobDeduction() {
    const annualIncome = aIncome * 12
    if (annualIncome <= jobThreshold)
        return 0
    const deductible = annualIncome - jobThreshold
    const deduction = deductible * jobPercentage
    if(deduction > jobDeductibleLimit)
        return jobDeductibleLimit
    return deduction
}

function calculateTaxes(taxRate) {
    taxable = format(aIncome - monthlyDeductions)
    const upperTaxable = taxable > upperTaxThreshold ? taxable - upperTaxThreshold : 0
    upperTax = upperTaxable * upperTaxRate
    baseTax = taxable * taxRate
}

function format(value) {
    if (value < 10)
        return 0
    const rest = value % 10
    return value - rest
}

function taxReport() {
    return {
        taxable,
        amContribution,
        "baseTax": Math.ceil(baseTax),
        "upperTax": Math.ceil(upperTax),
        "personalDeduction": Math.ceil(personalDeduction),
        "employmentDeduction": Math.ceil(employmentDeduction),
        "jobDeduction": Math.ceil(jobDeduction),
        monthlyDeductions,
        aIncome
    }
}