import Input from "@/components/shared/controls/input/Input.vue"
import AMControls from "@/components/shared/controls/amControls/AmControls.vue"
import Headline from "@/components/shared/Headline/Headline.vue"

export default {
  name: 'tax-rate-selector',
  components: {Input, AMControls, Headline},
  props: {
    salaryInfo: {}
  },
  data () {
    return {

    }
  },
  computed: {
    taxRate : function(){
      return Math.round(this.salaryInfo.taxRate*100)
    }
  },
  mounted () {

  },
  methods: {
    updateTaxRate: function(target){
      const value = target.value
      if(value < 0)
        target.value = 0
      else if(value > 100)
        target.value = 100
      this.salaryInfo.taxRate = target.value / 100
    },
    decreaseRate: function(){
      let value = this.salaryInfo.taxRate * 100
      if(value < 0)
        return
      this.salaryInfo.taxRate = Math.round(--value) / 100
    },
    increaseRate: function(){
      let value = this.salaryInfo.taxRate * 100
      if(value > 100)
        return
      this.salaryInfo.taxRate = Math.round(++value) / 100
    }
  }
}


