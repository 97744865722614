<div class="salary-sel-cont">
  <Headline text="Indtast indkomst">
    <div style="width: 192px;">
      <h1>Info</h1>
      <p>
        Indtast din timeløn og antallet af timer. <br><br> 
        Du kan vælge om det skal
        være ugentlige eller månedlige timer.
      </p>
    </div>
  </Headline>
  <div class="salary-form-cont" v-if="salaryInfo.hasJob">
    <Input placeholder="Indtast timeløn" label="Timeløn" :value="this.salaryInfo.hourlyRate"
      :updateCallback="updateHourlyRate" />
    <Input placeholder="Indtast månedlige timer" label="Timer" :value="hours" :updateCallback="updateHours" />
    <Select label="Periode" :model="salaryInfo.hourPeriod" :onupdate="updatePeriod">
      <option value="weekly">Ugentlig</option>
      <option value="monthly">Månedlig</option>
    </Select>
  </div>
  <div class="not-job-input">
    <Input v-if="!salaryInfo.hasJob" placeholder="Indtast beløb i kr." label="dkk"
      :value="this.salaryInfo.welfareSubsidy" :updateCallback="updateWelfareSubsidy" />
  </div>
</div>