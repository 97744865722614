export default function (salaryInfo) {
    const brutto = calcSalary(salaryInfo)
    const pension = salaryInfo.hasJob ? calcPension(brutto, salaryInfo) : 0
    const atp = salaryInfo.hasJob ? calcATP(salaryInfo.monthlyHours) : 0
    const totalBenefits = calculateTotalBenefits(salaryInfo.benefits)
    const bruttoWithBenefits = brutto + totalBenefits
    const withDrawal = atp + pension
    const amBasis = withDrawal <= bruttoWithBenefits ? bruttoWithBenefits - withDrawal : 0
    return {
        "atp": atp,
        "brutto": Math.ceil(brutto),
        "amBasis": Math.ceil(amBasis),
        "pension": Math.ceil(pension),
        "benefits": salaryInfo.benefits,
        totalBenefits
    }
}

function calcSalary(salaryInfo) {
    if (!salaryInfo.hasJob)
        return salaryInfo.welfareSubsidy
    const hours = salaryInfo.monthlyHours
    const rate = salaryInfo.hourlyRate
    return hours * rate
}

function calcPension(salary, salaryInfo) {
    if (salaryInfo.pensionMode == "relative")
        return salary * salaryInfo.pensionPercentage / 100
    else if (salaryInfo.pensionMode == "absolute" && salaryInfo.pensionAmount < salary)
        return Number.parseInt(salaryInfo.pensionAmount)
    else
        return 0
}

const calcATP = monthlyHours => {
    if (monthlyHours >= 117)
        return 99
    else if (monthlyHours >= 78)
        return 66
    else if (monthlyHours >= 39)
        return 33
    return 0
}

function calculateTotalBenefits(benefits) {
    return benefits
        .map(benefit => Number.parseInt(benefit.value))
        .reduce((acc, val) => acc + val, 0)
}