import InfoBox from "@/components/shared/InfoBox/InfoBox.vue"

export default {
  name: 'headline',
  components: {InfoBox},
  props: [
    "text"
  ],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


