import * as Salaries from "@/services/salaries/salaries.js"
import FinalReport from "@/components/salaries/finalReport/FinalReport.vue"
import TaxRateSelector from "@/components/salaries/taxRateSelector/TaxRateSelector.vue"
import EmploymentSelector from "@/components/salaries/employmentSelector/EmploymentSelector.vue"
import PensionSelector from "@/components/salaries/pensionSelector/PensionSelector.vue"
import SalarySelector from "@/components/salaries/salarySelector/SalarySelector.vue"
import Benefits from "@/components/salaries/benefits/Benefits.vue"

export default {
  name: 'home',
  components: {
    FinalReport,
    TaxRateSelector,
    PensionSelector,
    EmploymentSelector,
    SalarySelector,
    Benefits
  },
  props: [],
  data() {
    return {
      salaryInfo: Salaries.info()
    }
  },
  computed: {
  },
  mounted() {

  },
  methods: {
    saveInfo: function () {
      Salaries.saveInfo()
      this.showToast("Dine ændringer er gemt")
    },
    reset: function () {
      if (confirm("Er du sikker?")) {
        Salaries.clearInfo()
        this.showToast("Oplysninger gendannet. Genindlæs for ændringer til at træde i kraft.")
      }
    },
    showToast: function (message) {
      const toast = document.getElementById("toast")
      const toastMessage = document.getElementById("toast-message")
      toastMessage.textContent = message
      toast.style.display = "block"
      toast.style.opacity = 1
      setTimeout(() => this.hideToast(toast), 2500);
    },
    hideToast: function (toast) {
      toast.style.opacity = 0
      setTimeout(() => toast.style.display = "none", 1250)
    }
  }
}


