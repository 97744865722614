import Input from "@/components/shared/controls/input/Input.vue"
import Radios from "@/components/shared/controls/radios/Radios.vue"
import AMControls from "@/components/shared/controls/amControls/AmControls.vue"
import Headline from "@/components/shared/Headline/Headline.vue"

const pensionModes = ["none", "relative", "absolute"]

export default {
  name: 'pension-selector',
  components: { Input, Radios, AMControls, Headline},
  props: {
    salaryInfo: {}
  },
  data() {
    return {
      pensionLabels: ["Ingen", "Procent", "Fast"]
    }
  },
  computed: {
    pensionIndex: function(){
      const mode = this.salaryInfo.pensionMode
      return pensionModes.indexOf(mode)
    }
  },
  mounted() {
  },
  methods: {
    updatePensionMode: function (currentIndex) {
      const pensionMode = pensionModes[currentIndex]
      if (!pensionMode)
        return
      this.salaryInfo.pensionMode = pensionMode
    },
    updatePensionPercentage: function (target) {
      if (target.value < 0)
        target.value = 0
      else if (target.value > 100)
        target.value = 100
      this.salaryInfo.pensionPercentage = target.value
    },
    updatePensionAmount: function (target) {
      const am = target.value
      if (am < 0)
        target.value = 0
      this.salaryInfo.pensionAmount = target.value
    },
    decreasePercentage: function(){
      if(this.salaryInfo.pensionPercentage > 0)
        this.salaryInfo.pensionPercentage--
    },
    increasePercentage: function(){
      this.salaryInfo.pensionPercentage++
    }
  }
}