import Label from "@/components/shared/controls/label/Label.vue"

export default {
  name: 'select',
  components: {Label},
  props: [
    "label",
    "model",
    "onupdate"
  ],
  data () {
    return {
      internalModel: ""
    }
  },
  computed: {

  },
  mounted () {
    if(this.model)
      this.internalModel = this.model
  },
  methods: {
    handleChange : function(){
      if(this.onupdate)
        this.onupdate(this.internalModel)
    }
  }
}


