import ScaleOnScroll from "@/services/scrollAnimations/scaleOnScroll"


export default {
  name: 'scale-animation-view',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
    ScaleOnScroll.performAnimation()
  },
  methods: {
    handleScroll: function (e) {
      ScaleOnScroll.performAnimation()
    }
  }
}


