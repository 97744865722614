import SalaryReport from "./salaryReport.js"
import TaxReport from "./taxReport.js"

export default function (salaryInfo) {
    const salaryReport = SalaryReport(salaryInfo)
    const taxReport = TaxReport(salaryInfo, salaryReport)
    const paymentReport = salaryPaymentReport(taxReport, salaryReport)
    return {
        salaryReport, taxReport, paymentReport
    }
}

function salaryPaymentReport(taxReport, salaryReport) {
    const amBasis = salaryReport.amBasis
    const aIncome = amBasis - taxReport.amContribution
    const taxes = taxReport.baseTax + taxReport.upperTax
    const netto = aIncome - taxes - salaryReport.totalBenefits
    return {
        netto,
        taxes
    }
}