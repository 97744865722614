<div v-if="salaryInfo.hasJob" class="pension-cont">
  <Headline text="Indtast pensions oplysninger">
    <div style="width: 192px;">
      <h2>Info</h2>
      <p>
        Angiv om pensionen er en procentdel af din løn eller et fast beløb.<br><br>
      </p>
    </div>
  </Headline>
  <div class="pension-form">
    <Radios :labels="pensionLabels" :selectedIndex="this.pensionIndex" :updateCallback="updatePensionMode" />
    <div v-if="salaryInfo.pensionMode == 'relative'" class="rel-sel-wrapper">
      <Input placeholder="Procentpoint" label="%" :value="salaryInfo.pensionPercentage"
        :updateCallback="updatePensionPercentage" />
      <AMControls :decreaseCallback="decreasePercentage" :increaseCallback="increasePercentage" />
    </div>
    <div class="abs-sel-wrapper" v-if="salaryInfo.pensionMode == 'absolute'">
      <Input placeholder="Beløb" label="dkk"
        :value="salaryInfo.pensionAmount" :updateCallback="updatePensionAmount" />
    </div>
  </div>
</div>