<section class="tax-benefits">
  <h2>Skattefrie goder</h2>
  <div class="ben-form">
    <Input :value="title" type="string" label="Titel" :updateCallback="updateTitle" placeholder="Navn på gode" />
    <Input :value="value" type="Integer" label="DKK" :updateCallback="updateValue" />
    <input class="btn btn-primary" type="button" value="Tilføj" :onclick="addBenefit" />
  </div>
  <br>
  <div class="ben-cont">
    <div class="ben-item-cont" v-for="benefit in salaryInfo.benefits">
      <div class="ben-item">
        <p class="ben-el ben-title">{{ benefit.title }}</p>
        <p class="ben-el ben-value">{{ benefit.value }} kr.</p>
      </div>
      <button class="dup-btn btn btn-primary" :onclick="() => duplicateBenefit(benefit.id)">+</button>
      <button class="rem-btn btn btn-danger" :onclick="() => removeBenefit(benefit.id)">x</button>
    </div>
  </div>
</section>