import Radios from "@/components/shared/controls/radios/Radios.vue"

export default {
  name: 'employment-selector',
  components: {Radios},
  props: {
    salaryInfo: {}
  },
  data () {
    return {
      employmentLabels: ["På offentlig ydelse","I beskæftigelse"]
    }
  },
  computed: {
    employmentIndex: function(){
      return this.salaryInfo.hasJob ? 1 : 0
    }
  },
  mounted () {
  },
  methods: {
    updateEmploymentMode: function(index){
      this.salaryInfo.hasJob = index == 1 ? true : false
    }
  }
}


