<div class="page-cnt horizontal-center">
  <h1 id="page-title">Løn udregner</h1>
  <br>
  <h2>Bemærk!</h2>
  <p>
    Resultatet er kun vejledende og kan afvige en del fra det faktiske.
  </p>
  <br>
  <div class="selectors-cont responsive-layout">
    <div class="form-cnt">
      <EmploymentSelector :salaryInfo="this.salaryInfo"/>
      <SalarySelector :salaryInfo="this.salaryInfo"/>
      <Benefits :salaryInfo="this.salaryInfo" />
      <PensionSelector :salaryInfo="this.salaryInfo"/>
      <TaxRateSelector :salaryInfo="this.salaryInfo"/>
    </div>
    <div class="report-wrap">
      <FinalReport :salaryInfo="salaryInfo" />
    </div>
  </div>
  <div id="buttons-row">
    <button id="my-btn" :onclick="saveInfo" type="button" class="btn btn-primary">Gem</button>
    <button id="my-btn" :onclick="reset" type="button" class="btn btn-danger">Gendan</button>
  </div>
  <div class="toast horizontal-center" role="alert" aria-live="assertive" aria-atomic="true">
    <div id="toast-message" class="toast-body"></div>
  </div>
</div>