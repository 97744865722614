const templateInfo = {
    pensionMode: "relative",
    benefits: [],
    pensionPercentage: 1,
    pensionAmount: 300,
    welfareSubsidy: 11201,
    taxRate: 0.36,
    hasJob: true,
    hourlyRate: 200,
    monthlyHours: 160.33,
    hourPeriod: "monthly"
}

let userInfo = templateInfo

export const saveInfo = () => {
    const json = JSON.stringify(userInfo)
    localStorage.setItem("userInfo", json)
}

export const clearInfo = () => {
    localStorage.clear()
    userInfo = templateInfo
}

export const info = () => {
    const info = localStorage.getItem("userInfo")
    if (info)
        userInfo = JSON.parse(info)
    return userInfo
}
